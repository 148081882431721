
import {defineComponent} from "vue";

export default defineComponent({
  name: "EntitySettingsMenuMobile",
  methods: {
    goTo(routeName: string) {
      this.$router.push({name: `${this.entityType.toLowerCase()}-settings-${routeName}`})
    }
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    },
    entityType(): string {
      return this.isOrganization ? 'Organization' : 'Production'
    }
  },
})
